export const windows = {
  en: {
    download: {
      title: 'Download',
      items: ['2.1.63 was the previous release. Two variants are available:', '2.1.64 was just released.'],
    },
    links: {
      name: [
        'Download Anki for Windows 10/11 (2.1.63 Qt6)',
        'Download Anki for Windows 10/11 (2.1.63 Qt5)',
        'Download Anki for Windows 10/11 (2.1.64 Qt6)',
        'Download Anki for Windows 7/8/8.1 (2.1.64 Qt5)',
        'Installation & troubleshooting guide',
        'Change notes & older releases',
        'Alternate download site',
        'Alternate changes site',
      ],
      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-windows-qt6.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-windows-qt5.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-windows-qt6.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-windows-qt5.exe',
        'https://docs.ankiweb.net/platform/windows/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requirements',
      items: [
        'Recent Anki releases require a computer running the 64 bit version of Windows 10 or 11.',
        'The last Anki release that supported Windows 7 and 8.1 was Anki 2.1.49.',
        'The last Anki release that supported 32 bit Windows was Anki 2.1.35-alternate.',
        "If you're on an old machine, you can obtain old releases from the",
        'releases page',
      ],
      links: ['https://github.com/ankitects/anki/releases'],
    },
    installing: {
      title: 'Installating',
      items: [
        'To install Anki:',
        'Download Anki from',
        'https://brazil.ankipro.net',
        'See thenext section for how to choose between -qt5 and -qt6.',
        'Save the installer to your desktop or downloads folder.',
        'Double-click on the installer to run it. If you see an error message, please see the links on the left.',
        'Once Anki is installed, double-click on the new star icon on your desktop to start Anki.',
      ],
      links: ['https://brazil.ankipro.net'],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'Recent Anki versions come in separate Qt5 and Qt6 variants. The Qt6 version is recommended for most users.',
        'Advantages of the Qt6 version:',
        'Various bugfixes, including things like better support for less common languages.',
        'Very large images load faster than the Qt5 version.',
        'Security updates. Support for the Qt5 library was discontinued in Nov 2020, meaning that any security flaws discovered since then will remain unfixed.',
        'Disadvantages of the Qt6 version:',
        'Some add-ons currently only work with the Qt5 version.',
      ],
      links: [],
    },
    upgrading: {
      title: 'Upgrading',
      items: [
        'If upgrading from Anki 2.1.6+, there is no need to uninstall the previous version first. All you need to do is close Anki if it is open, and then follow the installation steps above. Your cards will be preserved when you upgrade.',
        "If upgrading from an Anki version before 2.1.6, or switching from the standard to the alternate version or vice versa, we recommend uninstalling the old version first, which will remove Anki's program data, but not delete your card data.",
        'If you wish to downgrade to a previous version, please make sure you',
        'downgrade first',
        '⚠ After using the latest version, if you wish to open your collection with an earlier Anki release, please go to the File > Switch Profile menu item, and click on "Downgrade & Quit". If you skip this step, you may get an error message when opening your collection in an older Anki version, and you will need to return to this version, downgrade, then try again.',
      ],
      links: ['https://changes.ankiweb.net'],
    },
    addon: {
      title: 'Add-on Compatibility',
      items: [
        'Some add-ons have not been updated to support the latest Anki release yet. If an add-on you depend on has not been ported to the latest release yet, you may have more luck with 2.1.44 from the',
        'releases page',
      ],
      links: ['https://github.com/ankitects/anki/releases'],
    },
  },
  pt: {
    download: {
      title: 'Download',
      items: [
        '2.1.63 foi a versão anterior. Duas variantes estão disponíveis:',
        '2.1.64 acabou de ser lançada.',
      ],
    },
    links: {
      name: [
        'Baixe Anki para Windows 10/11 (2.1.63 Qt6)',
        'Baixe Anki para Windows 10/11 (2.1.63 Qt5)',
        'Baixe Anki para Windows 10/11 (2.1.64 Qt6)',
        'Baixe Anki para Windows 7/8/8.1 (2.1.64 Qt5)',
        'Instalação & guia de solução de problemas',
        'Notas de alterações & versões mais antigas',
        'Site alternativo para download',
        'Site alternativo de alterações',
      ],

      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-windows-qt6.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-windows-qt5.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-windows-qt6.exe',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-windows-qt5.exe',
        'https://docs.ankiweb.net/platform/windows/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requirements',
      items: [
        'As versões recentes do Anki requerem um computador executando a versão de 64 bits do Windows 10 ou 11.',
        'A última versão do Anki com suporte para Windows 7 e 8.1 foi Anki 2.1.49.',
        'A última versão do Anki que suportava o Windows de 32 bits foi Anki 2.1.35-alternate.',
        'Se você estiver em uma máquina antiga, poderá obter versões antigas na',
        'página de versões',
      ],
      links: ['https://github.com/ankitects/anki/releases'],
    },
    installing: {
      title: 'Instalação',
      items: [
        'Para instalar Anki:',
        'Baixe de',
        'https://brazil.ankipro.net/english',
        'Consulte a próxima seção para saber como escolher entre -qt5 e -qt6.',
        'Salve o instalador na área de trabalho ou na pasta de downloads.',
        'Clique duas vezes no instalador para executá-lo. Se você vir uma mensagem de erro, consulte os links de guia de solução de problemas.',
        'Depois que o Anki estiver instalado, clique duas vezes no novo ícone de estrela em sua área de trabalho para iniciar o Anki.',
      ],
      links: ['https://brazil.ankipro.net/english'],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'As versões recentes do Anki vêm em variantes Qt5 e Qt6 separadas. A versão Qt6 é recomendada para a maioria dos usuários.',
        'Vantagens da versão Qt6:',
        'Várias correções de bugs, incluindo coisas como: melhor suporte para idiomas menos comuns.',
        'Imagens muito grandes carregam mais rápido que a versão Qt5.',
        'Atualizações de segurança. O suporte para a biblioteca Qt5 foi descontinuado em novembro de 2020, o que significa que qualquer falhas de segurança descobertas desde então permanecerão sem correção.',
        'Desvantagens da versão Qt6:',
        'Alguns complementos atualmente funcionam apenas com a versão Qt5.',
      ],
      links: [],
    },

    upgrading: {
      title: 'Atualizando',
      items: [
        'Se estiver atualizando de uma versão do Anki posterior a Anki 2.1.6+, não há necessidade de desinstalar a versão anterior primeiro. Tudo que precisa fazer é fechar o Anki, se estiver aberto, e seguir as etapas de instalação acima. Seus baralhos serão preservados quando você atualizar.',
        'Se estiver atualizando de uma versão do Anki anterior a 2.1.6, ou mudar da versão padrão para a alternativa ou vice-versa, recomendamos desinstalar a versão antiga primeiro, o que removerá os dados do programa do Anki, mas não excluirá os dados dos seus baralhos.',
        'Se você deseja fazer o downgrade para uma versão anterior, certifique-se de fazer',
        'downgrade primeiro',
        '⚠ Depois de usar a versão mais recente, se desejar abrir sua coleção com uma versão anterior do Anki, vá para o item de menu Arquivo > Alternar perfil e clique em "Downgrade & quit". Se você pular esta etapa, poderá receber uma mensagem de erro ao abrir sua coleção em uma versão mais antiga do Anki e precisará retornar a esta versão, fazer o downgrade e tentar novamente.',
      ],
      links: ['https://changes.ankiweb.net'],
    },
    addon: {
      title: 'Compatibilidade de Complementos',
      items: [
        'Alguns complementos ainda não foram atualizados para oferecer suporte à versão mais recente do Anki. Se um complemento do qual você depende ainda não foi portado para a versão mais recente, você pode ter mais sorte com a versão 2.1.44 na',
        'página de versões',
      ],
      links: ['https://github.com/ankitects/anki/releases'],
    },
  },
};
