import styles from './CardDetails.module.css';
import { useLayoutEffect, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// components
import Navbar from '../../components/Navbar';
import Cards from '../../pages/decks/Cards';
// data
import { english } from '../../data/language/English';
import { dataDecks } from '../../data/decks/Decks';
import { SuperSEO } from 'react-super-seo';

const CardDetails = () => {
  const { deckId } = useParams();
  const deck = dataDecks.decks.find((deck: any) => deck.url === deckId);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [showImage, setShowInmage] = useState(false);

  useEffect(() => {
    const hide = () => {
      const btn = document.querySelector('.btn-data') as HTMLElement;
      if (showImage) {
        btn.innerHTML = 'Show more images';
        setShowInmage(false);
      }
    };
    return () => {
      hide();
    };
  });

  const imgShowHide = () => {
    setShowInmage(!showImage);
    const btn = document.querySelector('.btn-data') as HTMLElement;
    if (showImage) {
      btn.innerHTML = 'Show less images';
    } else {
      btn.innerHTML = 'Show more images';
    }
  };

  return (
    <>
      <SuperSEO lang={english.SEO.lang} title={`${deck?.title} - Anki Decks`}>
        <link rel="canonical" href={`https://brazil.ankipro.net/decks/${deckId}`} />
        <meta name="description" content={deck?.description} />
      </SuperSEO>
      <Navbar navbar={english.navbar} />
      {deck && (
        <div className={styles.card}>
          <div className={styles.container}>
            <h2>{deck.title}</h2>
            <p>{deck.description}</p>
            <span>This deck has approximately {deck.notes} notes.</span>
            <div className={styles.image}>
              {deck.imgs.map((img: any) => (
                <img loading="lazy" key={img} src={img} alt="img" />
              ))}
            </div>
            {deck.moreImgs && (
              <>
                {deck.moreImgs.length > 0 && (
                  <button onClick={imgShowHide} className="btn-data">
                    Show less images
                  </button>
                )}
                {!showImage && (
                  <div className={styles.showImage}>
                    {deck.moreImgs.map((img: any) => (
                      <img loading="lazy" key={img} src={img} alt="img" />
                    ))}
                  </div>
                )}
              </>
            )}

            {deck.details && deck.details.map((detail: any) => detail)}
            <a className="btn-data" href={deck.download}>
              Download
            </a>
          </div>
        </div>
      )}
      <Cards />
    </>
  );
};

export default CardDetails;
