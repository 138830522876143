import styles from "./HomeSection4.module.css";
import { useEffect, useState } from "react";
// interface
import { IHomeSystems } from "../../Interfaces/Interfaces";

const HomeSection4 = ({ windows, mac, linux, mobile, dev }: IHomeSystems) => {
  const [tab, setTab] = useState("");

  const isActive = (e: React.MouseEvent) => {
    e.preventDefault();
    e.currentTarget.getAttribute("href")?.replace("#", "");
    const target = e.target as HTMLElement;
    const parent = target.parentElement as HTMLElement;
    const tab = parent.getAttribute("id");

    if (tab) {
      resetTabs();
      const tabList = document.getElementById(tab + "-list");
      const tabPane = document.getElementById(tab);
      tabPane?.classList.add(`${styles.active}`);
      tabList?.classList.add(`${styles.active}`);
    }
  };

  const resetTabs = () => {
    const listPane = ["windows", "mac", "linux", "mobile", "dev"];
    const tabsList = ["windows-list", "mac-list", "linux-list", "mobile-list", "dev-list"];

    for (let i = 0; i < listPane.length; i++) {
      const tabPane = document.getElementById(listPane[i]);
      const tabList = document.getElementById(tabsList[i]);
      tabPane?.classList.remove(`${styles.active}`);
      tabList?.classList.remove(`${styles.active}`);
    }
  };

  useEffect(() => {
    const platform = window.navigator.userAgent;
    if (platform.startsWith("Win")) {
      setTab("windows");
    } else if (platform.match("Mac")) {
      setTab("mac");
    } else if (platform.match("Linux; Android") || platform.match("Android")) {
      setTab("mobile");
    } else if (platform.match("iPhone")) {
      setTab("mobile");
    } else if (platform.match("Linux")) {
      setTab("linux");
    } else {
      setTab("windows");
    }

    if (tab !== "") {
      const tabPane = document.getElementById(tab + "-list");
      const tabList = document.getElementById(tab);
      if (tabPane) {
        tabPane.classList.add(`${styles.active}`);
      }
      if (tabList) {
        tabList.classList.add(`${styles.active}`);
      }
    }
  }, [tab]);

  return (
    <section className={styles.container}>
      <h2 id="download">Download Anki</h2>
      <div>
        <ul className={styles.navTabs}>
          <li id="windows">
            <a onClick={isActive} href="#windows" data-toggle="tab">
              Windows
            </a>
          </li>
          <li id="mac">
            <a href="#mac" onClick={isActive} data-toggle="tab">
              Mac
            </a>
          </li>
          <li id="linux">
            <a href="#linux" onClick={isActive} data-toggle="tab">
              Linux
            </a>
          </li>
          <li id="mobile">
            <a href="#mobile" onClick={isActive} data-toggle="tab">
              iPhone/Android
            </a>
          </li>
          <li id="dev">
            <a href="#dev" onClick={isActive} data-toggle="tab">
              Development
            </a>
          </li>
        </ul>
        <hr />
        <div>
          <div id="unknown" className={styles.tabPane}></div>
          <div className={styles.tabPane} id="windows-list">
            <h2>{windows.download.title}</h2>
            <p className={styles.removePaddingMargin}>
              {windows.download.items[0]}{" "}
              <a href="https://docs.ankiweb.net/platform/windows/installing.html#qt5-vs-qt6">Qt5 vs Qt6</a>.
            </p>
            <div className={styles.containerList}>
              <div>
                <p>
                  <a href={windows.links.link[0]} className="btn btn-large">
                    {windows.links.name[0]}
                  </a>
                </p>
                <p>
                  <a href={windows.links.link[1]} className="btn">
                    {windows.links.name[1]}
                  </a>
                </p>
                <p>{windows.download.items[1]}</p>
                <p>
                  <a href={windows.links.link[2]} className="btn btn-large">
                    {windows.links.name[2]}
                  </a>
                </p>
                <p>
                  <a href={windows.links.link[3]} className="btn">
                    {windows.links.name[3]}
                  </a>
                </p>
                <p>
                  <a className="btn btn-large btn-info" href={windows.links.link[4]}>
                    {windows.links.name[4]}
                  </a>
                </p>
                <p>
                  <a className="btn btn-large btn-info" href={windows.links.link[5]}>
                    {windows.links.name[5]}
                  </a>
                </p>
                <ul>
                  <li>
                    <a href={windows.links.link[6]}>{windows.links.name[6]}</a>
                  </li>
                  <li>
                    <a href={windows.links.link[7]}>{windows.links.name[7]}</a>
                  </li>
                </ul>
              </div>
              <div className={styles.containerList2}></div>
            </div>
            <h2>{windows.requirements.title}</h2>
            <p>{windows.requirements.items[0]}</p>
            <ul>
              <li>{windows.requirements.items[1]}</li>
              <li>{windows.requirements.items[2]}</li>
            </ul>
            <p>
              {windows.requirements.items[3]}{" "}
              <a href={windows.requirements.links[0]}>{windows.requirements.items[4]}</a>.
            </p>
            <h2>{windows.installing.title}</h2>
            <p>{windows.installing.items[0]}</p>
            <ol>
              <li>
                {windows.installing.items[1]}{" "}
                <a href="https://brazil.ankipro.net">https://brazil.ankipro.net</a>.{" "}
                {windows.installing.items[3]}
              </li>
              <li>{windows.installing.items[4]}</li>
              <li>{windows.installing.items[5]}</li>
              <li>{windows.installing.items[6]}</li>
            </ol>
            <h2>{windows.qt5vsqt6.title}</h2>
            <p>{windows.qt5vsqt6.items[0]}</p>
            <p>{windows.qt5vsqt6.items[1]}</p>
            <ul>
              <li>{windows.qt5vsqt6.items[2]}</li>
              <li>{windows.qt5vsqt6.items[3]}</li>
              <li>{windows.qt5vsqt6.items[4]}</li>
            </ul>
            <p>{windows.qt5vsqt6.items[5]}</p>
            <ul>
              <li>{windows.qt5vsqt6.items[6]}</li>
            </ul>
            <h2>{windows.upgrading.title}</h2>
            <p>{windows.upgrading.items[0]}</p>
            <p>{windows.upgrading.items[1]}</p>
            <p>
              {windows.upgrading.items[2]}{" "}
              <a href={windows.upgrading.links[0]}>{windows.upgrading.items[3]}</a>.
            </p>
            <span>{windows.upgrading.items[4]}</span>
            <h2>{windows.addon.title}</h2>
            <p>
              {windows.addon.items[0]} <a href={windows.addon.links[0]}>{windows.addon.items[1]}</a>.
            </p>
          </div>

          <div className={styles.tabPane} id="mac-list">
            <h2>{mac.download.title}</h2>
            <p className={styles.removePaddingMargin}>
              {mac.download.items[0]}{" "}
              <a href="https://docs.ankiweb.net/platform/mac/installing.html#qt5-vs-qt6">Qt5 vs Qt6</a>.
            </p>
            <div className={styles.containerList}>
              <div>
                <p>
                  <a href={mac.links.link[0]} className="btn btn-large">
                    {mac.links.name[0]}
                  </a>
                </p>
                <p>
                  <a href={mac.links.link[1]} className="btn btn-large">
                    {mac.links.name[1]}
                  </a>
                </p>
                <p>
                  <a href={mac.links.link[2]} className="btn">
                    {mac.links.name[2]}
                  </a>
                </p>
                <p>{mac.download.items[1]}</p>
                <p>
                  <a href={mac.links.link[3]} className="btn btn-large">
                    {mac.links.name[3]}
                  </a>
                </p>
                <p>
                  <a href={mac.links.link[4]} className="btn btn-large">
                    {mac.links.name[4]}
                  </a>
                </p>
                <p>
                  <a href={mac.links.link[5]} className="btn">
                    {mac.links.name[5]}
                  </a>
                </p>
                <p>
                  <a className="btn btn-large btn-info" href={mac.links.link[6]}>
                    {mac.links.name[6]}
                  </a>
                </p>
                <p>
                  <a className="btn btn-large btn-info" href={mac.links.link[7]}>
                    {mac.links.name[7]}
                  </a>
                </p>

                <ul>
                  <li>
                    <a href={mac.links.link[8]}>{mac.links.name[8]}</a>
                  </li>
                  <li>
                    <a href={mac.links.link[9]}>{mac.links.name[9]}</a>
                  </li>
                </ul>
              </div>
              <div className={styles.containerList2}></div>
            </div>
            <h2>{mac.requirements.title}</h2>
            <p>{mac.requirements.items[0]}</p>
            <p>
              {mac.requirements.items[1]}{" "}
              <a href="https://github.com/ankitects/anki/releases">{mac.requirements.links[0]}</a>.
            </p>
            <h2>{mac.installing.title}</h2>
            <p>{mac.installing.items[0]}</p>
            <ol>
              <li>
                {mac.installing.items[1]} <a href="https://brazil.ankipro.net">https://brazil.ankipro.net</a>.{" "}
                {mac.installing.items[2]}
              </li>
              <li>{mac.installing.items[3]}</li>
              <li>{mac.installing.items[4]}</li>
              <li>{mac.installing.items[5]}</li>
            </ol>
            <h2>{mac.qt5vsqt6.title}</h2>
            <p>{mac.qt5vsqt6.items[0]}</p>
            <p>{mac.qt5vsqt6.items[1]}</p>
            <ul>
              <li>{mac.qt5vsqt6.items[2]}</li>
              <li>{mac.qt5vsqt6.items[3]}</li>
              <li>{mac.qt5vsqt6.items[4]}</li>
            </ul>
            <p>{mac.qt5vsqt6.items[5]}</p>
            <ul>
              <li>{mac.qt5vsqt6.items[6]}</li>
              <li>{mac.qt5vsqt6.items[7]}</li>
            </ul>
            <h2>{mac.upgrading.title}</h2>
            <p>{mac.upgrading.items[0]}</p>
            <h2>{mac.addon.title}</h2>
            <p>
              {mac.addon.items[0]}{" "}
              <a href="https://github.com/ankitects/anki/releases">{mac.addon.links[0]}</a>.
            </p>
          </div>
          <div className={styles.tabPane} id="linux-list">
            <h2>{linux.download.title}</h2>
            <p className={styles.removePaddingMargin}>
              {linux.download.items[0]}{" "}
              <a href="https://docs.ankiweb.net/platform/linux/installing.html#qt5-vs-qt6">Qt5 vs Qt6</a>.
            </p>
            <div className={styles.containerList}>
              <div>
                <p>
                  <a href={linux.links.link[0]} className="btn btn-large">
                    {linux.links.name[0]}
                  </a>
                </p>
                <p>
                  <a href={linux.links.link[1]} className="btn">
                    {linux.links.name[1]}
                  </a>
                </p>
                <p>{linux.download.items[1]}</p>
                <p>
                  <a href={linux.links.link[2]} className="btn btn-large">
                    {linux.links.name[2]}
                  </a>
                </p>
                <p>
                  <a href={linux.links.link[3]} className="btn">
                    {linux.links.name[3]}
                  </a>
                </p>
                <p>
                  <a
                    className="btn btn-large btn-info"
                    href="https://docs.ankiweb.net/platform/linux/installing.html"
                  >
                    Installation &amp; troubleshooting guide
                  </a>
                </p>
                <p>
                  <a className="btn btn-large btn-info" href="https://github.com/ankitects/anki/releases">
                    Change notes &amp; older releases
                  </a>
                </p>
                <ul>
                  <li>
                    <a href="https://apps.ankiweb.net/downloads/archive">Alternate download site</a>
                  </li>
                  <li>
                    <a href="https://changes.ankiweb.net">Alternate changes site</a>
                  </li>
                </ul>
              </div>
              <div className={styles.containerList2}></div>
            </div>
            <h2>{linux.requirements.title}</h2>
            <p>
              {linux.requirements.items[0]}{" "}
              <a href="https://betas.ankiweb.net/#via-pypipip">{linux.requirements.links[0]}</a>.
            </p>
            <p>{linux.requirements.items[1]}</p>
            <pre>
              <code>{linux.requirements.items[2]}</code>
            </pre>
            <p>
              {linux.requirements.items[3]}{" "}
              <a href="https://docs.ankiweb.net/platform/linux/missing-libraries.html">
                {linux.requirements.links[1]}
              </a>
              .
            </p>
            <p>{linux.requirements.items[4]}</p>
            <h2>{linux.installing.title}</h2>
            <p>{linux.installing.items[0]}</p>
            <ol>
              <li>
                {linux.installing.items[1]}{" "}
                <a href="https://brazil.ankipro.net">https://brazil.ankipro.net</a>.{" "}
                {linux.installing.items[2]}
              </li>
              <li>
                {linux.installing.items[3]}{" "}
                <code>{linux.installing.codeItems?.length && linux.installing.codeItems[0]}</code>.
              </li>
              <li>{linux.installing.items[4]}</li>
            </ol>
            <pre>
              <code>{linux.installing.codeItems?.length && linux.installing.codeItems[1]}</code>
            </pre>
            <p>
              {linux.installing.items[5]}{" "}
              <code>{linux.installing.codeItems?.length && linux.installing.codeItems[2]}</code>.
            </p>
            <ol start={4}>
              <li> {linux.installing.items[6]}</li>
            </ol>
            <h2>{linux.qt5vsqt6.title}</h2>
            <p>{linux.qt5vsqt6.items[0]}</p>
            <p>{linux.qt5vsqt6.items[1]} </p>
            <ul>
              <li>
                {linux.qt5vsqt6.items[2]}{" "}
                <a href="https://docs.ankiweb.net/platform/linux/blank-window.html">
                  {linux.qt5vsqt6.links[0]}
                </a>
                ).
              </li>
              <li>{linux.qt5vsqt6.items[3]}</li>
              <li>{linux.qt5vsqt6.items[4]}</li>
              <li>{linux.qt5vsqt6.items[5]}</li>
              <li>{linux.qt5vsqt6.items[6]}</li>
            </ul>
            <p>{linux.qt5vsqt6.items[7]}</p>
            <ul>
              <li>{linux.qt5vsqt6.items[8]}</li>
            </ul>
            <h2>{linux.upgrading.title}</h2>
            <p>{linux.upgrading.items[0]}</p>
            <p>{linux.upgrading.items[1]}</p>
            <p>
              {linux.upgrading.items[2]} <a href="http://changes.ankiweb.net">{linux.upgrading.links[0]}</a>.
            </p>
            <h2>{linux.addon.title}</h2>
            <p>
              {linux.addon.items[0]}{" "}
              <a href="https://github.com/ankitects/anki/releases">{linux.addon.links[0]}</a>.
            </p>
          </div>
          <div className={styles.tabPane} id="dev-list">
            <h2>{dev.title[0]}</h2>
            <p>{dev.items[0]}</p>
            <p>{dev.items[1]}</p>
            <ul>
              <li>
                <a href={dev.links.link[0]}>{dev.links.name[0]}</a>
              </li>
              <li>
                <a href={dev.links.link[1]}>{dev.links.name[1]}</a>
              </li>
            </ul>
            <h2>{dev.title[1]}</h2>
            <p>
              {dev.items[2]} <a href={dev.links.link[2]}>{dev.links.name[2]}</a>.
            </p>
            <p>{dev.items[3]}</p>
            <h2>{dev.title[2]}</h2>
            {dev.items[4]} <a href={dev.links.link[3]}>{dev.links.name[3]}</a> {dev.items[5]}
          </div>
          <div className={styles.tabPane} id="mobile-list">
            <h2>{mobile.title[0]}</h2>
            <p>
              <a
                href="https://itunes.apple.com/us/app/ankimobile-flashcards/id373493387"
                target="itunes_store"
                className="btn btn-primary btn-large"
              >
                {mobile.links[0]}
              </a>
            </p>
            <p>
              {mobile.items[0]} <a href="https://brazil.ankipro.net/web">AnkiWeb</a>.
            </p>
            <h2>{mobile.title[1]}</h2>
            <p>
              <a
                href="https://play.google.com/store/apps/details?id=com.ichi2.anki"
                className="btn btn-primary btn-large"
              >
                {mobile.links[1]}
              </a>
            </p>
            {mobile.items[1]}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection4;
